import React from "react";
import { useRouter } from "next/router";
import { Clickable } from "artisn-ui-react";

import Styles from "./GoBack.styles";
import { GoBackProps as Props } from "./GoBack.types";

import ChevronLeft from "../../../../public/assets/images/chevron-left-black.svg";

const GoBack: React.FC<Props> = props => {
  const { title = "", width = 0, className, to } = props;
  const router = useRouter();

  const clickHandler = () => {
    if (to) {
      router.replace(to);
      return;
    }
    router.back();
  };

  return (
    <Styles className={`GoBack ${className}`} width={width} title={title}>
      <Clickable onClick={clickHandler} className="GoBack__icon">
        <ChevronLeft viewBox="0 0 22 22" />
        {title ? <p className="GoBack__title">{title}</p> : null}
      </Clickable>
    </Styles>
  );
};

GoBack.defaultProps = {
  className: ""
};

export default GoBack;
