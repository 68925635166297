import React from "react";
import Link from "next/link";

import Styles from "./Logo.styles";
import { LogoProps as Props } from "./Logo.types";
import CONSTANTS from "config/constants";

import LogoSVG from "../../../../public/assets/images/logo.svg";
import LogoMobileSVG from "../../../../public/assets/images/logo.svg";

const { TITLE } = CONSTANTS;

const Logo: React.FC<Props> = props => {
  const { className, onClick } = props;
  const label = `${TITLE} logo`;

  return (
    <Styles className={`Logo ${className}`}>
      {onClick ? (
        <div onClick={onClick} aria-label={label}>
          <LogoMobileSVG className="Logo__mobile" viewBox="0 0 155 39" />
          <LogoSVG className="Logo__desktop" viewBox="0 0 155 39" />
        </div>
      ) : (
        <Link href="/" passHref aria-label={label}>
          <a className="Logo__anchor-container" aria-label={label}>
            <LogoMobileSVG className="Logo__mobile" viewBox="0 0 155 39" />
            <LogoSVG className="Logo__desktop" viewBox="0 0 155 39" />
          </a>
        </Link>
      )}
    </Styles>
  );
};

Logo.defaultProps = {
  className: ""
};

export default Logo;
