import React from "react";
import Link from "next/link";

import Styles from "./Footer.styles";
import { FooterProps as Props } from "./Footer.types";
import Logo from "../Logo/Logo";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";
import CONSTANTS from "config/constants";

import SmartphoneSVG from "../../../../public/assets/images/smartphone.svg";
import WhatsappSVG from "../../../../public/assets/images/whatsapp.svg";
import FacebookSVG from "../../../../public/assets/images/facebook-circle.svg";
import InstagramSVG from "../../../../public/assets/images/instagram-circle.svg";

const { WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;

const Footer: React.FC<Props> = props => {
  const { className } = props;
  const { talkShopProvider } = useTalkShop();
  const fullYear = new Date().getFullYear();

  if (talkShopProvider && WITH_TALK_SHOP) return null;

  const termsLinkNode = (
    <Link href="/terms">
      <a>Términos y condiciones</a>
    </Link>
  );

  const privacyLinkNode = (
    <Link href="/privacy">
      <a>Política de privacidad</a>
    </Link>
  );

  const faqLinkNode = (
    <Link href="/profile/faq">
      <a>Preguntas frecuentes</a>
    </Link>
  );

  return (
    <Styles className={`Footer ${className}`}>
      <div className="Footer__first-section">
        <Logo className="Footer__logo" />
        <ul className="Footer__link-list">
          <li className="Footer__link">
            <a
              href="https://pizzeriaelhornero.com.ec/trabajo/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Sé parte de hornero
            </a>
          </li>
          <li className="Footer__link">
            <a
              href="https://pizzeriaelhornero.com.ec/nosotros/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Nosotros
            </a>
          </li>
          <li className="Footer__link">
            <a
              href="http://elhornero.comprobante-electronico.com/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Comprobantes
            </a>
          </li>
          <li className="Footer__link">
            <Link href="/profile/stores">
              <a>¿Dónde estamos?</a>
            </Link>
          </li>
          <li className="Footer__link">
            <a
              href="https://pizzeriaelhornero.com.ec/contactos/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Contactos
            </a>
          </li>
          <li className="Footer__link">
            <a
              href="https://pizzeriaelhornero.com.ec/sugerenciasreclamos/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Sugerencias
            </a>
          </li>
        </ul>
      </div>
      <div className="Footer__second-section">
        <div className="Footer__second-section__content">
          <div className="Footer__second-section__item">
            <SmartphoneSVG />
            <p className="Footer__second-section__data">1700 005 005</p>
          </div>
          <div className="Footer__second-section__item">
            <WhatsappSVG />
            <p className="Footer__second-section__data">0999 005 005</p>
          </div>
          <div className="Footer__social-networks__icons">
            <a
              href="https://facebook.com/pizzeriaelhornero"
              rel="noreferrer noopener"
              target="_blank"
            >
              <FacebookSVG viewBox="0 0 40 40" />
            </a>
            <a
              href="https://www.instagram.com/elhornero.ec/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <InstagramSVG viewBox="0 0 40 40" />
            </a>
          </div>
        </div>
      </div>
      <div className="Footer__third-section">
        <div className="Footer__additional-info">
          <p className="Footer__additional-links">
            {termsLinkNode} | {privacyLinkNode} | {faqLinkNode}
          </p>
          <p className="Footer__copyright">
            ⓒ Hornero {fullYear}. Todos los derechos reservados.
          </p>
        </div>
        <p className="Footer__additional-info-mobile">
          ⓒ Hornero {fullYear}. Todos los derechos reservados. {termsLinkNode} |{" "}
          {privacyLinkNode} | {faqLinkNode}
        </p>
        <p className="Footer__author">
          Desarrollado por{" "}
          <a href="https://trade.ec/" rel="noreferrer noopener" target="_blank">
            Trade
          </a>
        </p>
      </div>
    </Styles>
  );
};

Footer.defaultProps = {
  className: ""
};

export default Footer;
