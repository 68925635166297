import styled from "styled-components";

import { FooterStyledProps as Props } from "./Footer.types";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const FooterStyled = styled.div<Props>`
  background-color: var(--palette-white);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: repeat(3, auto);
  grid-template-areas: ". first ." "second second second" ". third .";
  border-top: 0.1rem solid var(--palette-lightgrey-s5-l90);

  .Footer {
    &__first-section {
      grid-area: first;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-areas: "logo link-list";
      column-gap: 7.2rem;
      padding: 4rem 7.2rem;
      padding-bottom: 2.4rem;

      @media (max-width: ${tablet}px) {
        row-gap: 5.6rem;
        grid-template-columns: 1fr;
        grid-template-areas:
          "logo"
          "link-list";
        padding: 3.2rem 2.4rem;
      }
    }

    &__logo {
      grid-area: logo;

      @media (max-width: ${tablet}px) {
        justify-self: center;
      }
    }

    &__link-list {
      display: grid;
      grid-area: link-list;
      grid-template: repeat(2, 1fr) / repeat(3, auto);
      row-gap: 2rem;
      width: 100%;
      max-width: 53.6rem;
      list-style: none;

      @media (max-width: ${tablet}px) {
        grid-template: repeat(3, 1fr) / repeat(2, auto);
        justify-self: center;
        justify-content: space-between;
        gap: 1.6rem;
      }
    }

    &__link {
      font-size: 1.6rem;
      font-weight: 800;

      @media (max-width: ${tablet}px) {
        color: var(--palette-black-s0-l20);
      }

      a {
        text-decoration: none;
        color: var(--palette-primary);
      }
    }

    &__second-section {
      display: grid;
      grid-template-columns: var(--sizes-page-columns);
      grid-area: second;
      padding: 1.2rem 0;
      background-color: var(--palette-primary);

      @media (max-width: ${mobile}px) {
        padding: 1.6rem 0;
      }

      &__content {
        grid-column: content-start / content-end;
        display: flex;
        justify-content: space-between;
        padding: 0 7.2rem;

        @media (max-width: ${tablet}px) {
          padding: 0;
        }

        @media (max-width: ${mobile}px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          justify-items: center;
          row-gap: 1.6rem;
        }
      }

      &__item {
        display: flex;
        align-items: center;
      }

      &__data {
        margin-left: 0.8rem;
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: 800;
        color: var(--palette-white);
      }
    }

    &__social-networks {
      @media (max-width: ${tablet}px) {
        justify-self: center;
        margin-bottom: 3.2rem;
      }

      &__icons {
        display: flex;
        align-items: center;

        @media (max-width: ${mobile}px) {
          grid-column: 1 / 3;
        }

        > * {
          display: flex;
        }

        > * :not(:last-child) {
          margin-right: 1.6rem;
        }

        svg {
          width: 1.6rem;
          height: 1.6rem;

          @media (max-width: ${tablet}px) {
            width: 2.4rem;
            height: 2.4rem;
          }

          path {
            fill: var(--palette-white);
          }
        }
      }
    }

    &__third-section {
      grid-area: third;
      display: grid;
      grid-template-columns: 1fr auto;
      padding: 2.4rem 7.2rem;
      border-top: 0.1rem solid var(--palette-lightgrey-s5-l90);

      @media (max-width: ${tablet}px) {
        grid-template-columns: 1fr;
        padding: 2.4rem 0;
        padding-bottom: 3.2rem;
      }
    }

    &__additional-info {
      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__additional-links {
      margin-bottom: 1.6rem;

      a {
        font-size: 1.6rem;
        color: var(--palette-gray-s0-l50);
        text-decoration: none;
      }
    }

    &__copyright {
      font-size: 1.4rem;
      color: var(--palette-gray-s0-l50);
    }

    &__additional-info-mobile {
      display: none;
      text-align: center;
      margin-bottom: 2rem;
      font-size: 1.4rem;
      color: var(--palette-gray-s0-l50);

      @media (max-width: ${tablet}px) {
        display: block;
      }

      a {
        font-size: 1.4rem;
        color: var(--palette-gray-s0-l50);
        text-decoration: none;
      }
    }

    &__author {
      font-size: 1.4rem;
      color: var(--palette-gray-s0-l50);

      @media (max-width: ${tablet}px) {
        text-align: center;
      }

      > a {
        font-weight: 800;
        color: var(--palette-gray-s0-l50);
        text-decoration: none;
      }
    }
  }
`;

export default FooterStyled;
