import styled from "styled-components";

import { LogoStyledProps as Props } from "./Logo.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const LogoStyled = styled.div<Props>`
  .Logo {
    &__mobile {
      display: none;

      @media (max-width: ${tablet}px) {
        display: initial;
        width: 19.2rem;
        height: initial;
      }
    }

    &__desktop {
      max-width: 15.5rem;
      height: min-content;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }
  }
`;

export default LogoStyled;
